import React from 'react';
import { Box, Grid, Typography, Link, Button } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

const StyledButton = styled(Button)({
    backgroundColor: '#6a1b9a', // Custom color for the button
    color: '#fff',
    '&:hover': {
        backgroundColor: '#4a148c',
    },
});

// Define motion variants for animation
const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
};

const ContentSection = ({ reverse, heading, text, imgSrc, onLearnMoreClick }) => (
    <motion.div
        variants={sectionVariants}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.5 }}
    >
        <Box 
            sx={{ 
                padding: 4, 
                marginTop: '20px', 
               
            }}
        >
            <Grid container spacing={2} alignItems="stretch" direction={reverse ? 'row-reverse' : 'row'}>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            justifyContent: 'center',
                            paddingRight: reverse ? 0 : 2,
                            paddingLeft: reverse ? 2 : 0,
                        }}
                    >
                        <Typography variant="h4" component="h2" gutterBottom style={{ color: "white" }}>
                            <b> {heading}</b>
                        </Typography>
                        <Typography variant="body1" gutterBottom style={{ color: "white" }}>
                            {text}
                        </Typography>
                        <Link href="#" underline="hover" sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, color: '#377ded' }}>
                            {/* Download Brochure <Box component="span" sx={{ ml: 0.5 }}>📄</Box> */}
                        </Link>
                        <StyledButton variant="contained" onClick={() => onLearnMoreClick(heading)}>
                            Learn More
                        </StyledButton>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img
                            src={imgSrc}
                            alt={heading}
                            style={{ width: '100%', height: 'auto', maxHeight: '100%' }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </motion.div>
);

export default ContentSection;
