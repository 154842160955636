import React from 'react';
import { Box } from '@mui/material';
import HeadNav from '../components/HeadNav';
import Footer from '../components/Footer';
import ContentSection from './ContentSection'; // Adjust the import path if necessary

const Internships = () => {
    const handleLearnMoreClick = (courseName) => {
        const message = `I'm interested in learning more about the ${courseName} course.`;
        const phoneNumber = '+919778564277'; // Adjusted for international format without '+'
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank');
    };

    return (
        <div>
            <HeadNav />
            <Box 
                sx={{ 
                    backgroundColor: 'black', 
                    minHeight: '100vh', 
                    color: 'white', 
                    padding: 2
                }}
            >
                <h1 className='text-center p-3'>Internship Opportunities</h1>
                <ContentSection
                    heading="Python Development"
                    text="Our Python course covers fundamental programming concepts, data analysis, web development, and more. Ideal for beginners and those looking to advance their skills in this versatile language."
                    imgSrc="https://i.postimg.cc/ZnyYPBb9/Python-Symbol-removebg-preview.png"
                    reverse={false}
                    onLearnMoreClick={handleLearnMoreClick}
                />
                <ContentSection
                    heading="Flutter Development"
                    text="The Flutter course focuses on building natively compiled applications for mobile, web, and desktop from a single codebase. Learn to create beautiful and high-performance applications using Google's Flutter framework."
                    imgSrc="https://i.postimg.cc/8csJ2z1D/70760bf1e88b184bb1bc-removebg-preview.png"
                    reverse={true}
                    onLearnMoreClick={handleLearnMoreClick}
                />
                <ContentSection
                    heading="MERN Stack Development"
                    text="The MERN stack course covers MongoDB, Express.js, React, and Node.js. This comprehensive program teaches you how to build dynamic, full-stack web applications using the popular MERN stack."
                    imgSrc="https://i.postimg.cc/hjmPTQPX/79328284-f97b-489f-924c-eb3b17e34b56-image2-removebg-preview.png"
                    reverse={false}
                    onLearnMoreClick={handleLearnMoreClick}
                />
                <ContentSection
                    heading="UI/UX Design"
                    text="Our UI/UX design course provides essential skills in user interface and user experience design. Learn to create engaging and user-friendly designs for websites and applications, with a focus on design principles and user research."
                    imgSrc="https://i.postimg.cc/brKJ40CH/ux-ui-logo-removebg-preview.png"
                    reverse={true}
                    onLearnMoreClick={handleLearnMoreClick}
                />
            </Box>
            <Footer></Footer>
        </div>
    );
};

export default Internships;
